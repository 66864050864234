import { Settings } from '@/types'
import { App } from 'vue'
import VueGtag from 'vue-gtag'
export { event } from 'vue-gtag'
import VueHotjar from 'vue-hotjar-next'

export const init = (app: App<Element>, settings: Settings) => {
  if (import.meta.env.MODE === 'production') {
    const googleTagIdIsDefined = !!settings.googleAnalyticsKey
    const hotjarIdIsDefined = !!settings.hotjarKey
    if (googleTagIdIsDefined) {
      app.use(VueGtag, {
        config: {
          id: settings.googleAnalyticsKey ?? ''
        }
      })
    }
    if (hotjarIdIsDefined) {
      app.use(VueHotjar, {
        id: +settings.hotjarKey!,
        isProduction: import.meta.env.PROD,
        snippetVersion: 6
      })
    }
  }
}
