<template>
  <div data-testid="profile-options-container" class="flex w-full flex-col pb-4">
    <s-list-item
      :primary-text="translate('AppContainer.ModifyUserData')"
      :title="translate('AppContainer.ModifyUserData')"
      @item-click="openProfileUrl(ProfileOption.MANAGE_USER_DATA)"
    />
    <s-list-item
      :primary-text="translate('AppContainer.ChangePassword')"
      :title="translate('AppContainer.ChangePassword')"
      @item-click="openProfileUrl(ProfileOption.CHANGE_PASSWORD_URL)"
    />
  </div>
</template>

<script setup lang="ts">
import { useTranslator } from '@/plugins/translations'
import { useSettingsStore } from '@/store'
import { SListItem } from 'sima-suite-components-vue3'
import { computed, reactive } from 'vue'
import { useRoute } from 'vue-router'

const store = useSettingsStore()
const route = useRoute()

const queryParams = computed(() => {
  const params = new URLSearchParams()
  for (const [key, value] of Object.entries(route.query)) {
    const valueIsDefined = value ?? true
    if (!valueIsDefined || Array.isArray(value)) continue
    params.append(key, value!)
  }
  return params
})

const ProfileOption = reactive({
  CHANGE_PASSWORD_URL: store.urls.changePasswordUrl ?? '',
  MANAGE_USER_DATA: store.urls.manageUserDataUrl ?? ''
})

const { translate } = useTranslator()

const openProfileUrl = (url: string): void => {
  const params = queryParams?.value?.size > 0 ? '?' + queryParams.value.toString() : ''
  if (url) window.open(url + params, '_self')
}
</script>
