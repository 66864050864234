export enum IconColors {
  DILER = '#7ED2E0 #2DB7CF',
  LEADTRAY = '#63E4BC #58C7A5',
  ORDERING = '#97A6C6 #4F6BA8',
  SIMA_CARE = '#41C2FA #1E8FBF',
  SIMA_DESKTOP = '#FF577B #FF305C',
  FINANCE = '#AB4FAB #D864D8',
  WORKSHOP = '#74C481 #9AE7A7',
  DEFAULT = '#FF577B #FF305C',
  PARTS = '#6D59B9 #9383D2'
}