import { productFruits } from 'product-fruits'

export const init = ({
  key,
  language,
  user
}: {
  key: string | undefined
  language: string
  user?: { email: string; name: string }
}) => {
  if (!key || !user || user.email === '') return
  productFruits.init(key, language, {
    email: user.email,
    username: user.email,
    firstname: user.name
  })
}
