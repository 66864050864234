<template>
  <s-app-layout
    :title="translate('App.Title')"
    :src-image="appLogo"
    :user-profile="userData"
    :show-notifications="false"
    :toolbar-title="toolbarTitle"
    :language="appLanguages"
    :with-navigation-sidebar="false"
    @click:logout="logout"
    @click:current-language="onLanguageSelected"
  >
    <div class="ssc-flex-grow ssc-overflow-y-auto h-full">
      <router-view />
    </div>
    <template v-if="showUserDataManage" #profile-content>
      <profile-options />
    </template>
    <template #content-head-right>
      <s-icon-button
        role="button"
        :icon="'mdi-help'"
        aria-label="Help"
        title="Help"
        data-testid="help"
        @icon-button-click="onClickHelp"
      />
    </template>
    <template #toolbar-right-content>
      <s-button
        class="normal-case"
        color="#FF2957"
        text="Newsletter"
        @button-click="onClickNewsletter"
      />
    </template>
  </s-app-layout>
</template>
<script setup lang="ts">
import { useSettingsStore } from '@/store'
import { onBeforeMount, computed } from 'vue'
import {
  SAppLayout,
  SIconButton,
  setSimaSuiteComponentsLanguage,
  SButton
} from 'sima-suite-components-vue3'
import { Language } from './type'
import appLogo from '@/assets/applogo.svg'
import { ProfileOptions } from '@/components/index.ts'
import { useAuthentication } from '@/plugins/authentication'
import { useTranslator } from '@/plugins/translations'
import * as onboarding from '@/plugins/userOnboarding'
import { validate as uuidValidate } from 'uuid';

const { logout, getUserProfile } = useAuthentication()

const appStore = useSettingsStore()
const { translate, language, setLanguage } = useTranslator()
const showUserDataManage = computed(()=> userData.value ? uuidValidate(userData.value.sub) : false)

const onClickHelp = () => {
  window.open(appStore.urls.helpUrl, '_blank')?.focus()
}

const onClickNewsletter = () => {
  window.open(appStore.urls.newsletterUrl, '_blank')?.focus()
}

const languages = [{ key: 'es' }, { key: 'en' }]

const userData = computed(() => {
  return getUserProfile()
})

const toolbarTitle = computed(() => {
  return `${translate('App.Greeting')}, ${userData.value?.name}`
})

const appLanguages = computed(() => {
  const langs: { key: string; value: any }[] = []
  const currentLanguage = languages.find((lang: Language) => lang.key === language.value)

  langs.push(createAppLanguage(currentLanguage!.key))
  languages.forEach((lang: Language) => {
    if (lang.key !== language.value) {
      langs.push(createAppLanguage(lang.key))
    }
  })

  return {
    options: langs,
    visibility: true
  }
})

const createAppLanguage = (lang: string) => {
  return {
    key: lang,
    value: translate(`App.Language.${lang}`)
  }
}

const onLanguageSelected = (selectedLanguage: Language) => {
  setLanguage(selectedLanguage.key)
  setSimaSuiteComponentsLanguage(selectedLanguage.key as 'es' | 'en' | 'pt' | 'fr' | 'de' | 'hu')
}

onBeforeMount(async () => {
  const user = getUserProfile()
  onboarding.init({
    key: appStore.urls.productFruitKey,
    language: window.navigator.language.split('-')[0],
    user
  })
})
</script>

<style scoped>
:deep(.ssc-flex) {
  align-items: center;
}
</style>
